
import MC from "@/domain/session/MC.js";
import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import EmailUpdateRow from '@/portals/shared/cell/email/EmailUpdateRow';
import PhoneNumberUpdateRow from '@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow';
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import CountryUpdateRow from "@/portals/shared/cell/country/CountryUpdateRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";

import Address from "@/domain/model/address/Address.js"
import InvoiceSettings from "@/domain/model/invoice/InvoiceSettings.js"


export default class BillingUpdateViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }

    if (this.model().isStateContentLoading()) {
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.buildFormHeader();
      this.buildFormBody();
      this.buildFormFooter();
      return;
    }

    if (this.model().state() === "saving") {
      this.buildFormSaving();
    }
  } 

  buildFormSaving() {
    this.add(new LoadingDisplayRow());
    this.add(new StringDisplayRow().withValue("Saving"));
  }

  buildFormHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Update Billing and Invoice Details"));
  }

  buildFormBody() {
    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow()
                                .withTitle("Billing Company")
                                .withSubtitle(true))
                .withChild(new StringUpdateRow()
                                .withMandatoryValue(true)
                                .withDefaultValue(this.model().settings().companyName())
                                .withName("Billing Company Name")
                                .withField(InvoiceSettings.FIELDS.COMPANY_NAME)
                                .withKey("InvoiceSettings"))
              )
          .withChild(new SpacerDisplayRow().withWidth("2"))
      ));
    
    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow().withTitle("Billing Contact").withSubtitle(true))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().settings().contactName())
                              .withName("Contact First and Last Name")
                              .withField(InvoiceSettings.FIELDS.CONTACT_NAME)
                              .withKey("InvoiceSettings"))
                .withChild(new EmailUpdateRow()
                              .withDefaultValue(this.model().settings().contactEmail())
                              .withMandatoryValue(true)
                              .withName("Email")
                              .withField(InvoiceSettings.FIELDS.CONTACT_EMAIL)
                              .withKey("InvoiceSettings"))
                .withChild(new PhoneNumberUpdateRow()
                              .withDefaultValue(this.model().settings().contactPhone())
                              .withMandatoryValue(true)
                              .withName("Phone")
                              .withField(InvoiceSettings.FIELDS.CONTACT_PHONE)
                              .withKey("InvoiceSettings"))
                )
          .withChild(new SpacerDisplayRow().withWidth("2"))
          ));

      this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow().withTitle("Billing Address").withSubtitle(true))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().address().street())
                              .withName("Street")
                              .withField(Address.FIELDS.STREET)
                              .withKey("Address"))
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().address().city())
                              .withMandatoryValue(true)
                              .withName("City")
                              .withField(Address.FIELDS.CITY)
                              .withKey("Address"))
                .withChild(new JurisdictionUpdateRow()
                              .withDefaultValue(this.model().address().province())
                              .withName("Province")
                              .withField(Address.FIELDS.JURISDICTION)
                              .withKey("Address")
                              .withMandatoryValue(true))
                .withChild(new CountryUpdateRow()
                              .withDefaultValue(this.model().address().country())
                              .withName("Country")
                              .withField(Address.FIELDS.COUNTRY)
                              .withKey("Address")
                              .withMandatoryValue(true))
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().address().postal())
                              .withMandatoryValue(true)
                              .withName("Postal Code")
                              .withField(Address.FIELDS.POSTAL)
                              .withKey("Address"))
                )
          .withChild(new SpacerDisplayRow().withWidth("2"))
      ));
                  
      this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow().withTitle("Invoice Notification Emails and Purchase Order #").withSubtitle(true))
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().settings().emailTo())
                              .withMandatoryValue(true)
                              .withName("Email Invoices To")
                              .withField(InvoiceSettings.FIELDS.EMAIL_INVOICE_TO)
                              .withKey("InvoiceSettings")
                              .withHint("A copy the invoice will be sent to these addresses (separate each email address with a comma).")
                            )
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().settings().purchaseOrder())
                              .withMandatoryValue(false)
                              .withName("PO #")
                              .withField(InvoiceSettings.FIELDS.PURCHASE_ORDER)
                              .withKey("InvoiceSettings")
                              .withHint("Purchare order number will appear on the invoice")
                            )

              )
          .withChild(new SpacerDisplayRow().withWidth("2"))
        )
      )
  }

  buildFormFooter() {
    this.addFooter(new BoxDisplayRow()
          .withBorder(true)
          .withChild(
            new RowDisplayRow()
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("3").withName("Save").withField("Save").withBackgroundPrimaryAction())
              .withChild(new StringDisplayRow().withWidth("3"))
          )
      );
  }
}