import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import UpdateViewUtils from "./DisplayViewUtils";

export default class DisplayViewModel extends RcTableViewModel {
    
  constructor(panel, companyId) {
    super(panel);
    this._total = 0;
    this._error = null;
    this._settings = null;
    this._companyId = companyId;
    this._company = null;
  }

  start() {
    super.start();
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  settings() {
    return this._settings;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  loadItems() {
    this._company = this.domain().companys().findById(this._companyId);
    this._settings = this._company.invoiceSettings().find().copy();

    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    
    this.panel().complete();
    return this;
  }

  priceTimesheets() {
    if (this.hasFeatureTimesheets) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForTimesheets().toFixed(2);
    }
    return "$0.35";
  }

  priceVehicles() {
    if (this.hasFeatureVehicles) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForVehicles().toFixed(2);
    }
    return "$0.50";
  }

  priceInspections() {
    if (this.hasFeatureInspections) {
      var company = this.domain().session().company().find();
      var assets = company.assets().find()
      return assets.priceForInspections().toFixed(2);
    }
    return "$0.35";
  }

  hasFeatureVehicles() {
    return this.domain().session().company().features().find().allowsVehicles();
  }
  
  hasFeatureInspections() {
    return this.domain().session().company().features().find().allowsInspections();
  }

  hasFeatureTimesheets() {
    return this.domain().session().company().features().find().allowsTimesheets();
  }
}